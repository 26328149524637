import { ServedInfo } from "src/types";

const testnetBaseUrl = "https://testnet.venomscan.com/accounts";
const testnetAddress =
  "0:0e7f5d523719bf6a2aaa85e70d156d292b03b78f5b72f4bf93c17fc2ab4abfef";

// todo: mainnet
// const mainnetBaseUrl = "https://venomscan.com/accounts";
// const mainnetAddress =
// "0:077873f1453fa67b0f1ce77f1e806675acd19c4694b9738be61fd406618f2f7a";

export const servedInfo: {
  testnet: ServedInfo;
  // mainnet: ServedInfo;
} = {
  testnet: {
    servedAddress: {
      address: testnetAddress,
      link: `${testnetBaseUrl}/${testnetAddress}`,
    },
    // servedAmount: {
    //   amount: 1 * 10 ** 9,
    //   link: defaultLink,
    // },
  },
  // todo: mainnet
  // mainnet: {
  //   servedAddress: {
  //     address: mainnetAddress,
  //     link: `${mainnetBaseUrl}/${mainnetAddress}`,
  //   },
  //   // servedAmount: {
  //   //   amount: 1 * 10 ** 9,
  //   //   link: defaultLink,
  //   // },
  // },
};
